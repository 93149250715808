import React, { useState, useEffect, useRef } from 'react'
// import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row, Form, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from "moment";
import { Link } from 'react-router-dom'
import axios from 'axios';
import { Multiselect } from "multiselect-react-dropdown";
import { info, error, warning } from "../../../notify";
import './Dropdown.css'

import DeleteConfirmation from './DeleteConfirmation';
import Card from '../../../components/Card'
import '../../../assets/scss/component.scss'
import RetrainConfirmation from './RetrainConfirmation';
import { FaSearch } from 'react-icons/fa';

const SiteDocs = (props) => {
    let history = useNavigate();
    const userRole = sessionStorage.getItem("userRole");
    const ERR_NETWORK_MESSAGE = 'Server is not available.'
    const token = sessionStorage.getItem("revizeTrainingToken");
    const [domainKey, setDomainKey] = useState("");
    const [domainName, setDomainName] = useState("");
    const [enableFileTrain, setEnableFileTrain] = useState(false);
    const [siteId, setSiteId] = useState("");
    const [siteData, setSiteData] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [updatedFiles, setUpdatedFiles] = useState([]);
    const [selectedUrls, setSelectedUrls] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [documentCategoryData, setDocumentCategoryData] = useState([]);
    const [documentCategoryAvailableData, setDocumentCategoryAvailableData] = useState([]);
    const siteApi = `${process.env.REACT_APP_SERVER_URL}/api/site-trained-doc/?siteId=`;
    const categorySiteApi = `${process.env.REACT_APP_SERVER_URL}/api/category-site/?siteId=`;
    const deleteDocumentApi = `${process.env.REACT_APP_SERVER_URL}/api/train/doc?uuid=`;
    const addDocumentFileApi = `${process.env.REACT_APP_SERVER_URL}/api/train/file?websiteRef=`;
    const addCategoryDocumentFileApi = `${process.env.REACT_APP_SERVER_URL}/api/train/file/category?websiteRef=`;
    const addDocumentUrlApi = `${process.env.REACT_APP_SERVER_URL}/api/train/url?websiteRef=`;
    const addCategoryDocumentUrlApi = `${process.env.REACT_APP_SERVER_URL}/api/train/url/category?websiteRef=`;
    const updateDocumentFileApi = `${process.env.REACT_APP_SERVER_URL}/api/train/file?uuid=`;
    const updateDocumentUrlApi = `${process.env.REACT_APP_SERVER_URL}/api/train/url?websiteRef=`;
    const updateDocumentAllUrlApi = `${process.env.REACT_APP_SERVER_URL}/api/train/all/url?websiteRef=`;
    const documentCategoryApi = `${process.env.REACT_APP_SERVER_URL}/api/trained-doc-category/`;
    const documentCategoryAvailableApi = `${process.env.REACT_APP_SERVER_URL}/api/trained-doc-category-available/?website_id=`;
    const querySearchApi = `${process.env.REACT_APP_SERVER_URL}/api/get_query_search`;
    const getPageLimitApi = `${process.env.REACT_APP_SERVER_URL}/api/get_page_limit/`;
    const setPageLimitURL = `${process.env.REACT_APP_SERVER_URL}/api/set-page-limit/`;
    const [uuid, setUuid] = useState(null);
    const [confirmMessage, setConfirmMessage] = useState(null);
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
    const [deleteDocumentCategoryConfirmationModal, setDeleteDocumentCategoryConfirmationModal] = useState(false);
    const [displayRetrainConfirmationModal, setDisplayRetrainConfirmationModal] = useState(false);
    const [displayRetrainAllUrlConfirmationModal, setDisplayRetrainAllUrlConfirmationModal] = useState(false);

    const [showAddFile, setShowAddFile] = useState(false);
    const handleCloseAddFile = () => {
        setSelectedFiles([]);
        setSelectedCategories([]);
        setShowAddFile(false);
    }
    const handleShowAddFile = () => {
        getCategory();
        setShowAddFile(true);
    }
    const [showAddUrl, setShowAddUrl] = useState(false);
    const handleCloseAddUrl = () => {
        setSelectedUrls([]);
        setSelectedCategories([]);
        setShowAddUrl(false);
        setUrlError("");
    }
    const handleShowAddUrl = () => {
        getCategory();
        setShowAddUrl(true);
    }
    const [showUpdateUrl, setShowUpdateUrl] = useState(false);
    const handleCloseUpdateUrl = () => setShowUpdateUrl(false);
    const [showUpdateFile, setShowUpdateFile] = useState(false);
    const [updateUuid, setUpdateUuid] = useState('');
    const [urlError, setUrlError] = useState('');
    const [proceedTrain, setProceedTrain] = useState(true);
    const [filterValue, setFilterValue] = useState('');
    const [selectedCategories, setSelectedCategories] = useState([]);

    const handleCloseUpdateFile = () => {
        setShowUpdateFile(false);
        setUpdatedFiles([]);
    }

    const [documentUuid, setDocumentUuid] = useState('')
    const [documentUrl, setDocumentUrl] = useState('')
    const [websiteRef, setWebsiteRef] = useState('')
    const [categoryUuid, setCategoryUuid] = useState(null)
    const [categoryDocumentId, setCategoryDocumentId] = useState(null)
    const [showDocumentCategory, setShowDocumentCategory] = useState(false);
    const handleCloseDocumentCategory = () => {
        setDocumentCategoryData([]);
        setSelectedCategories([]);
        fetchData();
        setShowDocumentCategory(false);
    }
    const handleShowDocumentCategory = (document_id, uuid, url) => {
        getDocumentCategoryAvailable(document_id);
        getDocumentCategory(uuid);
        setDocumentUuid(uuid);
        setDocumentUrl(url);
        setShowDocumentCategory(true);
    }

    let loaded = false;
    const location = useLocation();

    useEffect(() => {
        if (!loaded && token) {
            // eslint-disable-next-line
            loaded = true;
            if (null === location.state) {
                history('/site')
                return;
            }
            let locData = location.state.siteKey;
            setSiteId(location.state.siteId);
            setDomainKey(location.state.siteKey);
            setDomainName(location.state.siteName);
            setEnableFileTrain(location.state.enableFileTrain);
            fetchData(locData);
        }

    }, [domainKey])

    const [isLoading, setIsLoading] = useState(false);
    const offset = useRef(0);


    const fetchCount = async (siteId, token, filter = null) => {
        const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
        let url = `${process.env.REACT_APP_SERVER_URL}/api/site-trained-doc-count/?siteId=${siteId}`;
        if (filter) {
            url += `&filter=${filter}`;
        }

        try {
            const response = await axios.get(url, { headers });
            setSiteDataLength(response.data)
            // return response.data; // The count of total documents

        } catch (err) {
            console.error('Error fetching count:', err);
            throw err;
        }
    };

    const fetchData = async (filter_value = null) => {
        if (siteId && token && !isLoading) {
            setIsLoading(true);
            const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
            const loggedInUserId = sessionStorage.getItem("loggedInUserId");
            let pageLimit = null;

            try {
                // Fetch the page limit first
                if (loggedInUserId) {
                    try {
                        const pageLimitResponse = await axios.get(getPageLimitApi, {
                            headers: headers,
                            params: { user_id: loggedInUserId }
                        });

                        const pageLimitData = pageLimitResponse.data;
                        pageLimit = pageLimitData.page_limit;  // Assign page limit
                        setItemsPerPage(pageLimitData.page_limit);  // Set the itemsPerPage from API response
                    } catch (pageLimitErr) {
                        if (pageLimitErr.response) {
                            error(pageLimitErr.response.data.detail, true);
                        } else {
                            console.error("Error fetching page limit:", pageLimitErr);
                        }
                        return;  // Stop further execution if fetching page limit fails
                    }
                }

                // Use the fetched pageLimit or existing itemsPerPage
                const limit = itemsPerPage ?? pageLimit;  // Use itemsPerPage if defined, else use pageLimit
                let url = `${siteApi}${siteId}&limit=${limit}&offset=${offset.current}`;
                if (filter_value) {
                    url += `&filter=${filter_value}`;
                }

                // Call the siteApi using the determined limit
                const response = await axios.get(url, { headers });
                const data = response.data;
                setSiteData(data);

            } catch (err) {
                if (err === 'ERR_NETWORK') {
                    error(ERR_NETWORK_MESSAGE, true);
                } else {
                    error(err.response.data.detail, true);
                }
                history('/site');
            } finally {
                setIsLoading(false);
            }
        }
    };


    const selectFile = (event) => {
        setSelectedFiles([event.target.files[0]])
    };

    const [isUrlValid, setIsUrlValid] = useState(false);

    const validateUrl = (event) => {
        event.preventDefault();
        // URL validation using regular expressions
        const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;

        if (!urlPattern.test(selectedUrls[0])) {
            setUrlError("URL is invalid");
            setIsUrlValid(false);
            // console.log("URL is invalid");
        } else {
            setUrlError("");
            setIsUrlValid(true);
        }
    }

    const updateFile = (event) => {
        setUpdatedFiles([event.target.files[0]])
    };

    const setUrl = (event) => {
        setSelectedUrls([event])
    };

    const onSelectCategories = (selectedList, selectedItem) => {
        setSelectedCategories([...selectedCategories, selectedItem.uuid]);
    };

    const onRemoveCategories = (selectedList, removedItem) => {
        setSelectedCategories(
            selectedCategories.filter((val) => {
                return val !== removedItem.uuid;
            })
        );
    }

    const getCategory = () => {
        if (token) {
            const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
            axios.get(categorySiteApi + siteId, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    setCategoryData(data);
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });
        }
    };

    const getDocumentCategoryAvailable = (document_id) => {
        if (token) {
            const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
            axios.get(documentCategoryAvailableApi + siteId + "&document_id=" + document_id, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    setDocumentCategoryAvailableData(data);
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });
        }
    };

    const getDocumentCategory = (uuid) => {
        if (token) {
            const headers = { "accept": "application/json", "Authorization": "Bearer " + token };
            axios.get(documentCategoryApi + uuid, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    // console.log(data);
                    setDocumentCategoryData(data);
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });
        }
    };

    const addDocumentCategoryFile = (documentUuid) => {
        if (token) {
            // console.log("documentUuid: " + documentUuid);
            const headers = { "Content-Type": "application/json", "accept": "application/json", "Authorization": "Bearer " + token };
            var data = JSON.stringify({
                "docUuid": documentUuid,
                "categories": selectedCategories
            });

            // console.log(data);

            axios.post(addCategoryDocumentFileApi + domainKey, data, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    info(data, true)
                    fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });

            setSelectedFiles([]);
            setSelectedCategories([]);
        }
    };

    const addDocumentCategoryUrl = (documentUuid) => {
        if (token) {
            // console.log("documentUuid: " + documentUuid);
            const headers = { "Content-Type": "application/json", "accept": "application/json", "Authorization": "Bearer " + token };
            var data = JSON.stringify({
                "docUuid": documentUuid,
                "categories": selectedCategories
            });

            axios.post(addCategoryDocumentUrlApi + domainKey, data, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    info(data, true)
                    // Not required for new changes.
                    // fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });

            setSelectedCategories([]);
        }
    };

    const addFileDocument = () => {
        if (token) {
            let currentFile = selectedFiles[0];
            let formData = new FormData();
            formData.append("files", currentFile);
            formData.append("categories", selectedCategories);
            formData.append("docUuids", [])
            const headers = { "accept": "application/json", "Authorization": "Bearer " + token };

            axios.post(addDocumentFileApi + domainKey, formData, { headers: headers })
                .then((response) => {
                    const data = { ...response.data, categories: "", categoryCount: 0 };
                    setSiteData(oldData => [...oldData, data]);
                    info("Accepted file for training", true)
                    fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });

            setSelectedFiles([]);
            setSelectedCategories([]);
        }
    };

    const addUrlDocument = () => {
        if (token) {
            const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
            var data = JSON.stringify({ "urls": selectedUrls, "categories": selectedCategories });

            axios.post(addDocumentUrlApi + domainKey, data, { headers: headers })
                .then((response) => {
                    const data = { ...response.data, categories: "", categoryCount: 0 };
                    setSiteData(oldData => [...oldData, data]);
                    info("Accepted URL for training.", true);
                    // Not required for new changes.
                    // fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });

            setSelectedCategories([]);
        }
    };

    const updateFileDocument = () => {
        if (token) {
            let formData = new FormData();
            formData.append("file", updatedFiles[0]);
            const headers = { "accept": "application/json", "Authorization": "Bearer " + token };

            axios.put(updateDocumentFileApi + updateUuid + "&websiteRef=" + domainKey, formData, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    updateSiteData(data.uuid, data.status)
                    info("Accepted URL for updation.", true);
                    fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });

            setUpdatedFiles([]);
            setSelectedCategories([]);
        }
    };

    const updateUrlDocument = () => {
        if (token) {
            const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
            var data = JSON.stringify({ "url": selectedUrls[0], "uuid": uuid });

            axios.put(updateDocumentUrlApi + domainKey, data, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    updateSiteData(data.uuid, data.status)
                    info("Accepted URL for updation.", true);
                    // Not required for new changes.
                    // fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });
        }
        setDisplayRetrainConfirmationModal(false);
    };

    const updateAllUrlDocument = () => {
        if (token) {
            const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };

            axios.put(updateDocumentAllUrlApi + domainKey, { headers: headers })
                .then((response) => {
                    const data = response.data;
                    if (data.length > 0) {
                        updateAllUrlSiteData(data);
                        info("Accepted all url for updation", true);
                    }
                    else {
                        error("THERE ARE NO URLs FOR TRAINING", true);
                    }
                    // Not required for new changes.
                    // fetchData();
                })
                .catch((err) => {
                    if (err === 'ERR_NETWORK') {
                        error(ERR_NETWORK_MESSAGE, true)
                    } else {
                        error(err.response.data.detail, true)
                    }
                });
        }
        setDisplayRetrainAllUrlConfirmationModal(false);
    };

    const updateAllUrlSiteData = (data) => {
        // map over the todos
        data.map((respItem) => {
            const updatedArray = siteData.map((item) => {
                // return the updated todo when we find the same id
                if (respItem.uuid === item.uuid) {
                    item.status = respItem.status;
                    return item;
                }

                return item;
            });
            setSiteData(updatedArray);
            return updatedArray;
        });
    };

    const deleteDocument = () => {
        const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
        var data = JSON.stringify({
            "websiteRef": domainKey,
            "category": "",
            "categoryDocumentId": 0
        });

        axios.delete(deleteDocumentApi + uuid, { data: data, headers: headers })
            .then((response) => {
                const data = response.data;
                info(data, true);
                setSiteData(
                    siteData.filter((val) => {
                        return val.uuid !== uuid;
                    })
                );
            })
            .catch((err) => {
                if (err === 'ERR_NETWORK') {
                    error(ERR_NETWORK_MESSAGE, true)
                } else {
                    error(err.response.data.detail, true)
                }
            });

        setDeleteConfirmationModal(false);
    };

    const deleteCategoryDocument = () => {
        // console.log("domainKey: " +  domainKey);
        const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
        var data = JSON.stringify({
            "websiteRef": websiteRef,
            "category": categoryUuid,
            "categoryDocumentId": categoryDocumentId
        });

        axios.delete(deleteDocumentApi + documentUuid, { data: data, headers: headers })
            .then((response) => {
                const data = response.data;
                info(data, true);
                // fetchData();
                setDocumentCategoryData(
                    documentCategoryData.filter((val) => {
                        return val.category_uuid !== categoryUuid;
                    })
                );
            })
            .catch((err) => {
                if (err === 'ERR_NETWORK') {
                    error(ERR_NETWORK_MESSAGE, true)
                } else {
                    error(err.response.data.detail, true)
                }
            });

        setDeleteDocumentCategoryConfirmationModal(false);
        setCategoryUuid(null);
        setCategoryDocumentId(null);

    };

    const updateSiteData = (uuid, status) => {
        const currentSiteDataIndex = siteData.findIndex((siteData) => siteData.uuid === uuid);
        const updatedSiteData = Object.assign({}, siteData[currentSiteDataIndex]);
        updatedSiteData.status = status;
        const newSiteData = siteData.slice();
        newSiteData[currentSiteDataIndex] = updatedSiteData;
        setSiteData(newSiteData);
    };

    const showDeleteModal = (uuid, docName) => {
        setUuid(uuid);
        setConfirmMessage("Are you sure you want to delete the document: " + docName + "? This will be deleted parmanently and can not be rolled back.");
        setDeleteConfirmationModal(true);
        loaded = true;
    };

    const showDeleteCategoryModal = (document_uuid, website_ref, category_uuid, id, category) => {
        setDocumentUuid(document_uuid);
        setWebsiteRef(website_ref);
        setCategoryUuid(category_uuid);
        setCategoryDocumentId(id);
        setConfirmMessage("Are you sure you want to delete the category: " + category + " for this document? This will be deleted parmanently and can not be rolled back.");
        setDeleteDocumentCategoryConfirmationModal(true);
        loaded = true;
    };

    const showRetrainModal = (uuid, url, status) => {
        const allow_train_status = ['TRAINED', 'RETRAINED', 'FAILED']
        if (allow_train_status.includes(status)) {
            setUuid(uuid);
            setUrl(url);
            setConfirmMessage("Are you sure you want to retrain with url: " + url + "? This will update old content of this url with new content and can not be rolled back.");
            setDisplayRetrainConfirmationModal(true);
            loaded = true;
            setProceedTrain(true);
        } else {
            setConfirmMessage("The URL is in " + status + " status and cannot be trained/retrained.");
            setDisplayRetrainConfirmationModal(true);
            loaded = true;
            setProceedTrain(false);
        }
    };

    const handleShowRetrainAllUrl = () => {
        setConfirmMessage("Are you sure you want to retrain all url in this site? This will update old content of these urls with new content and can not be rolled back.");
        setDisplayRetrainAllUrlConfirmationModal(true);
        loaded = true;
    };

    const hideRetrainAllUrlConfirmationModal = () => {
        setDisplayRetrainAllUrlConfirmationModal(false);
    }

    // Hide the modal
    const hideDeleteConfirmationModal = () => {
        setDeleteConfirmationModal(false);
    };

    const hideDeleteDocumentCategoryConfirmationModal = () => {
        setDeleteDocumentCategoryConfirmationModal(false);
    };

    const hideRetrainConfirmationModal = () => {
        setDisplayRetrainConfirmationModal(false);
    }

    const handleShowRetrainFile = (uuid, status) => {


        const allow_train_status = ['TRAINED', 'RETRAINED', 'FAILED']
        if (allow_train_status.includes(status)) {
            setUpdateUuid(uuid);
            getCategory();
            setShowUpdateFile(true);
        } else {
            setShowUpdateFile(false);
            warning('Cannot Train/Retrain the Page/Doc.');
        }


    }

    const filterSiteDocs = (event) => {
        setFilterValue(event);
        fetchData(event);
    }

    const refreshData = () => {
        loaded = true;
        fetchData(filterValue);
    }

    const handleDropdownOpen = () => {
        const dropdown = document.querySelector('.multiselect-container');
        if (dropdown) {
            dropdown.scrollTop = 0;  // Scroll to the top
        }
    }

    const checkURLOnCHange = (event) => {
        // URL validation using regular expressions
        setUrl(event.target.value)
        const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
        const isValidURL = urlPattern.test(selectedUrls[0])
        if (!isValidURL) {
            setUrlError("");
            setIsUrlValid(true);
        }
        else {
            setUrlError("URL is invalid");
            setIsUrlValid(false);
        }
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [currentRangeStart, setCurrentRangeStart] = useState(1);
    const [siteDataLength, setSiteDataLength] = useState(0)

    const [itemsPerPage, setItemsPerPage] = useState(null);
    const pagesToShow = 10;
    const totalPages = Math.ceil(siteDataLength / itemsPerPage);


    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
        offset.current = (pageNumber - 1) * itemsPerPage;
        fetchData(filterValue);
    };

    // Handlers for pagination range navigation
    const handleNextRange = () => {
        if (currentRangeStart + pagesToShow <= totalPages) {
            setCurrentRangeStart(currentRangeStart + pagesToShow);
        }
    };

    const handlePrevRange = () => {
        if (currentRangeStart - pagesToShow > 0) {
            setCurrentRangeStart(currentRangeStart - pagesToShow);
        }
    };

    // Page numbers based on current range
    const visiblePageNumbers = Array.from(
        { length: Math.min(pagesToShow, totalPages - currentRangeStart + 1) },
        (_, index) => currentRangeStart + index
    );

    useEffect(() => {
        if (siteId && token) {
            fetchData(filterValue); // Fetch initial data
            fetchCount(siteId, token, filterValue)
        }
    }, [siteId, token, filterValue]);

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(parseInt(e.target.value, 10));
        setCurrentPage(1);
    };


    useEffect(() => {

        const setPageLimit = async () => {
            const headers = {
                "accept": "application/json",
                "Authorization": "Bearer " + token
            };
            const loggedInUserId = sessionStorage.getItem("loggedInUserId");
            if (itemsPerPage != null) {
                try {
                    await axios.post(setPageLimitURL,
                        {
                            page_limit: itemsPerPage
                        },
                        {
                            headers: headers,
                            params: { user_id: loggedInUserId }
                        }
                    );

                    fetchData();
                }
                catch (setLimitErr) {
                    console.error('Error setting page limit:', setLimitErr);
                    error('Error setting page limit', true);
                }
            }

        };

        setPageLimit();

    }, [itemsPerPage]);

    const [showSearchField, setShowSearchField] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [fetchSource, setFetchSource] = useState(false)

    const handleSearchClick = () => {
        setShowSearchField(!showSearchField);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (searchQuery.trim() !== '') {
                event.preventDefault();
                setFetchSource(true)
                setShowSearchField(false)
                setSearchQuery('')
                setSiteData([])
                setShowPageNumbers(false);
                fetchQuerySource();
            }
        }
    };

    const handleQuerySearch = (event) => {
        if (searchQuery.trim() !== '') {
            event.preventDefault();
            setFetchSource(true)
            setShowSearchField(false)
            setSearchQuery('')
            setSiteData([])
            setShowPageNumbers(false);
            fetchQuerySource();
        }

    };

    const fetchQuerySource = () => {
        const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };

        const params = {
            query: searchQuery,
            websiteRef: domainKey,
            siteId: siteId
        };

        axios.get(querySearchApi, { params: params, headers: headers })
            .then((response) => {
                const data = response.data;
                setSiteData(data)
            })
            .catch((err) => {
                if (err === 'ERR_NETWORK') {
                    error(ERR_NETWORK_MESSAGE, true);
                } else {
                    error(err.response.data.detail, true);
                }
            });

        setFetchSource(false)
    };

    const [showPageNumbers, setShowPageNumbers] = useState(true);



    return (
        <>
            <Row>
                <Col sm="12">
                    <Card>
                        <Card.Header className="d-flex justify-content-between flex-wrap">
                            <div className="header-title">
                                <h4 className="card-title mb-0">Trained Documents/Pages - {domainName}</h4>
                            </div>
                            <div>
                                {/* Search button with tooltip */}
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-search">Search for source documents for a query</Tooltip>}
                                >
                                    <Button onClick={handleSearchClick} variant="primary">
                                        <FaSearch />
                                    </Button>
                                </OverlayTrigger>

                                {/* {showSearchField && (
                                    <div style={{
                                        position: 'absolute',
                                        zIndex: 1000,
                                        marginTop: '5px',
                                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                                        backgroundColor: 'white',
                                        padding: '10px',
                                        borderRadius: '4px',
                                        width: '250px',
                                    }}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter search query"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                            onKeyDown={handleKeyPress}
                                        />
                                    </div>
                                )} */}
                                <label className='dropdown-label'>Filter</label>
                                <select className="dropdown-button" onChange={e => filterSiteDocs(e.target.value)}>
                                    <option className="dropdown-option" value="">Select</option>
                                    <option className="dropdown-option" value="Trained">Trained</option>
                                    <option className="dropdown-option" value="Pending">Pending</option>
                                    <option className="dropdown-option" value="Failed">Failed</option>
                                </select>
                                <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShowRetrainAllUrl}>
                                    <i className="btn-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                    </i>
                                    <span>Retrain All URL</span>
                                </Button>
                                <Modal show={showAddUrl} onHide={handleCloseAddUrl}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add URL</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>URL</Form.Label>
                                            <Form.Control
                                                type="url"
                                                placeholder="URL"
                                                onChange={checkURLOnCHange}
                                                onBlur={validateUrl} />
                                            <span style={{ color: "red" }}>
                                                {urlError}
                                            </span>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Category</Form.Label>
                                            <Multiselect
                                                options={categoryData}
                                                onSelect={onSelectCategories}
                                                onRemove={onRemoveCategories}
                                                onDropdownToggle={handleDropdownOpen}
                                                placeholder="Select Category"
                                                displayValue="name" />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseAddUrl}>
                                            Cancel
                                        </Button>{' '}
                                        <Button disabled={selectedUrls.length === 0 || !isUrlValid} variant="primary" onClick={
                                            () => {
                                                addUrlDocument()
                                                handleCloseAddUrl()
                                            }
                                        }>
                                            Add
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                {enableFileTrain ?
                                    <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShowAddFile}>
                                        <i className="btn-inner">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                            </svg>
                                        </i>
                                        <span>Train with File</span>
                                    </Button>
                                    : null
                                }
                                <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShowAddUrl}>
                                    <i className="btn-inner">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                        </svg>
                                    </i>
                                    <span>Train with URL</span>
                                </Button>
                                <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3" onClick={refreshData}>
                                    Refresh
                                </Button>
                                <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3">
                                    <Link
                                        aria-current="page"
                                        to="/site"
                                    >
                                        <span style={{ 'color': 'white' }}> Back </span>
                                    </Link>
                                </Button>
                                <Modal show={showAddFile} onHide={handleCloseAddFile}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add Document</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Select Document</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={e => selectFile(e)}
                                                placeholder="Train with File"
                                            />
                                            <span style={{ color: "blue" }}>
                                                Note: Supported Document Types: pdf, docx
                                            </span>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Category</Form.Label>
                                            <Multiselect
                                                options={categoryData}
                                                onSelect={onSelectCategories}
                                                onRemove={onRemoveCategories}
                                                onDropdownToggle={handleDropdownOpen}
                                                placeholder="Select Category"
                                                displayValue="name" />
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseAddFile}>
                                            Cancel
                                        </Button>{' '}
                                        <Button disabled={selectedFiles.length === 0} variant="primary" onClick={
                                            () => {
                                                addFileDocument()
                                                handleCloseAddFile()
                                            }
                                        }>
                                            Add
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={showUpdateUrl} onHide={handleCloseUpdateUrl}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Retrain with URL</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseUpdateUrl} >
                                            Cancel
                                        </Button>{' '}
                                        <Button variant="primary" onClick={
                                            () => {
                                                updateUrlDocument()
                                                handleCloseUpdateUrl()
                                            }
                                        } >Update</Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showUpdateFile} onHide={handleCloseUpdateFile}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Retrain with File</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Retrain Document</Form.Label>
                                            <Form.Control
                                                type="file"
                                                onChange={e => updateFile(e)}
                                                placeholder="Retrain with File" />
                                            <span style={{ color: "blue" }}>
                                                Note: Supported Document Types: pdf, docx
                                            </span>
                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseUpdateFile}>
                                            Cancel
                                        </Button>{' '}
                                        <Button disabled={updatedFiles.length === 0} variant="primary" onClick={
                                            () => {
                                                updateFileDocument()
                                                handleCloseUpdateFile()
                                            }
                                        }>
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={showDocumentCategory} onHide={handleCloseDocumentCategory}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Category List</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="table-responsive">
                                            <table className="table-hover table-striped table">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">Category</th>
                                                        <th className="text-center">Status</th>
                                                        <th className="text-center">Action</th>
                                                        <th className="text-center">Last Trained</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {documentCategoryData.map((item, index) => (
                                                        <tr key={index} >
                                                            <td>
                                                                {item.category}
                                                            </td>
                                                            <td width={"7%"} style={{ textAlign: "center" }}>
                                                                {item.status === "FAILED" && <Badge bg="danger">
                                                                    {item.status}
                                                                </Badge>
                                                                }
                                                                {item.status === "PENDING" && <Badge bg="warning">
                                                                    {item.status}
                                                                </Badge>
                                                                }
                                                                {item.status === "TRAINED" && <Badge bg="light text-dark">
                                                                    {item.status}
                                                                </Badge>
                                                                }
                                                                {item.status === "RETRAINED" && <Badge bg="light text-dark">
                                                                    {item.status}
                                                                </Badge>
                                                                }
                                                                {item.status === "DELETED" && <Badge bg="secondary">
                                                                    {item.status}
                                                                </Badge>
                                                                }
                                                                {item.status === "TO_DELETE" && <Badge bg="warning">
                                                                    TO DELETE
                                                                </Badge>
                                                                }
                                                            </td>
                                                            <td width={"7%"} style={{ textAlign: "center" }}>
                                                                <div>
                                                                    <Link className="btn-sm btn-icon text-danger " data-bs-toggle="tooltip" title="Delete Document Category" to="#"
                                                                        onClick={() => { showDeleteCategoryModal(item.document_uuid, item.website_ref, item.category_uuid, item.id, item.category); }} >
                                                                        <span className="btn-inner">
                                                                            <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                                <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                                <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            </svg>
                                                                        </span>
                                                                    </Link>
                                                                </div>
                                                            </td>
                                                            <td width={"10%"} style={{ textAlign: "center" }}>
                                                                {item.last_trained ? moment(item.last_trained).format("MM/DD/YY, HH:mm") : ""}
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        <Form.Group className="mb-3" controlId="formBasicPassword">
                                            <Form.Label>Category</Form.Label>

                                            <Multiselect
                                                options={documentCategoryAvailableData}
                                                onSelect={onSelectCategories}
                                                onRemove={onRemoveCategories}
                                                onDropdownToggle={handleDropdownOpen}
                                                placeholder="Select Category"
                                                displayValue="name"
                                                style={{
                                                    multiselectContainer: { width: '100%' }, // Set the container width
                                                    option: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
                                                    chips: { background: '#0096fb', color: 'inherit' } // Adjust chip background/color
                                                }}
                                            />

                                        </Form.Group>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleCloseDocumentCategory}>
                                            Cancel
                                        </Button>{' '}
                                        <Button disabled={selectedCategories.length === 0} variant="primary" onClick={
                                            () => {
                                                documentUrl.length > 0 ? addDocumentCategoryUrl(documentUuid)
                                                    : addDocumentCategoryFile(documentUuid)
                                                handleCloseDocumentCategory()
                                            }
                                        }
                                        >
                                            Add
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                                <Modal show={displayRetrainAllUrlConfirmationModal} onHide={hideRetrainAllUrlConfirmationModal}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Retrain All URL</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className="alert alert-warn">
                                            {confirmMessage}
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={hideRetrainAllUrlConfirmationModal}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={() => updateAllUrlDocument()}>
                                            Retrain
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </Card.Header>
                        <Card.Body>
                            {showSearchField && (
                                <div className="d-flex justify-content-between mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter search query"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        onKeyDown={handleKeyPress}
                                        className="me-2"
                                        style={{ width: "full" }}  // Adjust the width of the search box if needed
                                    />
                                    <Button variant="primary" onClick={handleQuerySearch}>
                                        Search
                                    </Button>
                                </div>
                            )}
                            <div className="table-responsive">
                                <table className="table-hover table-striped table">
                                    <thead>
                                        <tr>
                                            {(userRole !== "ADMIN") && siteId === 'All' && <th className="text-center">Client</th>}
                                            <th className="text-center">Document Title</th>
                                            <th className="text-center">Category</th>
                                            <th className="text-center">Status</th>
                                            <th className="text-center">Action</th>
                                            <th className="text-center">Last Trained</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fetchSource ? (
                                            <tr>
                                                <td colSpan="6" className="text-center">Fetching...</td>
                                            </tr>
                                        ) : (!siteData.length
                                            ? <tr>
                                                <th className='no-data' colSpan="5">
                                                    {'No Records Found!'}
                                                </th>

                                            </tr>
                                            : (siteData.map((item, index) => (
                                                <tr key={index} >
                                                    {(userRole !== "ADMIN") && siteId === 'All' && <td width={"15%"}>{item.website_name}</td>}
                                                    <td title={item.doc_name}>
                                                        {item.doc_name.length > 75 ? item.doc_name.substring(0, 74) : item.doc_name} &nbsp;
                                                        {item.url.length > 0 ? <a href={item.url} target="_blank" rel="noreferrer">
                                                            <span className="text-primary">
                                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16px" height="16px">
                                                                    <path fillRule="evenodd" clipRule="evenodd" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" d="M 19.980469 2.9902344 A 1.0001 1.0001 0 0 0 19.869141 3 L 15 3 A 1.0001 1.0001 0 1 0 15 5 L 17.585938 5 L 8.2929688 14.292969 A 1.0001 1.0001 0 1 0 9.7070312 15.707031 L 19 6.4140625 L 19 9 A 1.0001 1.0001 0 1 0 21 9 L 21 4.1269531 A 1.0001 1.0001 0 0 0 19.980469 2.9902344 z M 5 3 C 3.9069372 3 3 3.9069372 3 5 L 3 19 C 3 20.093063 3.9069372 21 5 21 L 19 21 C 20.093063 21 21 20.093063 21 19 L 21 13 A 1.0001 1.0001 0 1 0 19 13 L 19 19 L 5 19 L 5 5 L 11 5 A 1.0001 1.0001 0 1 0 11 3 L 5 3 z" />
                                                                </svg>
                                                            </span>
                                                        </a> : null}

                                                    </td>
                                                    <td width={"25%"} style={{ textAlign: "center" }}>
                                                        {(null !== item.categories && item.categories.includes(','))
                                                            ?
                                                            <Link className="btn-sm btn-icon text-primary flex-end" data-bs-toggle="tooltip" title="List Document Category" to="#"
                                                                onClick={() => { handleShowDocumentCategory(item.id, item.uuid, item.url) }} >
                                                                {item.categoryCount > 5
                                                                    ? item.categories.split(',').slice(0, 5).map((obj) => (
                                                                        <Badge key={Math.random()} bg="light text-dark">
                                                                            {obj}
                                                                        </Badge>
                                                                    ))
                                                                    :
                                                                    item.categories.split(',').map((item) => (
                                                                        <Badge key={Math.random()} bg="light text-dark">
                                                                            {item}
                                                                        </Badge>
                                                                    ))
                                                                } {item.categoryCount > 5 ? String('+') + String(item.categoryCount - 5) : null}
                                                            </Link>
                                                            :
                                                            null !== item.categories
                                                                ?
                                                                <Link className="btn-sm btn-icon text-primary flex-end" data-bs-toggle="tooltip" title="List Document Category" to="#"
                                                                    onClick={() => { handleShowDocumentCategory(item.id, item.uuid, item.url) }} >
                                                                    <div to="#" className="iq-media-1">
                                                                        <Badge bg="light text-dark">{item.categories}</Badge>
                                                                    </div>
                                                                </Link>
                                                                :
                                                                <Link className="btn-sm btn-icon text-primary " data-bs-toggle="tooltip" title="List Document Category" to="#"
                                                                    onClick={() => { handleShowDocumentCategory(item.id, item.uuid, item.url) }} >
                                                                    <span className="btn-inner">
                                                                        <svg width="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M12.0001 8.32739V15.6537" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M15.6668 11.9904H8.3335" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M16.6857 2H7.31429C4.04762 2 2 4.31208 2 7.58516V16.4148C2 19.6879 4.0381 22 7.31429 22H16.6857C19.9619 22 22 19.6879 22 16.4148V7.58516C22 4.31208 19.9619 2 16.6857 2Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>
                                                        }
                                                    </td>
                                                    <td width={"7%"} style={{ textAlign: "center" }}>
                                                        {item.status === "FAILED" &&
                                                            (item.error_cause
                                                                ? (
                                                                    <OverlayTrigger
                                                                        placement="top"
                                                                        overlay={<Tooltip id={`tooltip-${item.id}`}>{item.error_cause}</Tooltip>}
                                                                    >
                                                                        <Badge bg="danger">
                                                                            {item.status}
                                                                        </Badge>
                                                                    </OverlayTrigger>
                                                                ) : (
                                                                    <Badge bg="danger">
                                                                        {item.status}
                                                                    </Badge>
                                                                )
                                                            )
                                                        }
                                                        {item.status === "PENDING" && <Badge bg="warning">
                                                            {item.status}
                                                        </Badge>
                                                        }
                                                        {item.status === "TRAINED" && <Badge bg="light text-dark">
                                                            {item.status}
                                                        </Badge>
                                                        }
                                                        {item.status === "RETRAINED" && <Badge bg="light text-dark">
                                                            {item.status}
                                                        </Badge>
                                                        }
                                                        {item.status === "DELETED" && <Badge bg="secondary">
                                                            {item.status}
                                                        </Badge>
                                                        }
                                                        {item.status === "TO_DELETE" && <Badge bg="warning">
                                                            TO DELETE
                                                        </Badge>
                                                        }
                                                    </td>
                                                    <td width={"7%"} style={{ textAlign: "center" }}>
                                                        <div>
                                                            {(filterValue != "Deleted") &&
                                                                <Link className="btn-sm btn-icon text-primary flex-end" data-bs-toggle="tooltip" title="Retrain" to="#"
                                                                    onClick={() => { item.url.length > 0 ? showRetrainModal(item.uuid, item.url, item.status) : handleShowRetrainFile(item.uuid, item.status) }}
                                                                >
                                                                    <span className="btn-inner">
                                                                        <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                                            <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </span>
                                                                </Link>
                                                            }
                                                            <Link className="btn-sm btn-icon text-danger " data-bs-toggle="tooltip" title="Delete" to="#" onClick={() => { showDeleteModal(item.uuid, item.doc_name); }} >
                                                                <span className="btn-inner">
                                                                    <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                                        <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                    </svg>
                                                                </span>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td width={"10%"} style={{ textAlign: "center" }}>
                                                        {item.last_trained ? moment(item.last_trained).format("MM/DD/YY, HH:mm") : ""}
                                                    </td>
                                                </tr>
                                            ))))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {!showPageNumbers ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                    <Button
                                        onClick={() => {
                                            fetchData();
                                            setShowPageNumbers(true); // Show page numbers again after fetching data
                                        }}
                                        className="mt-3"
                                    >
                                        Show Trained Documents
                                    </Button>
                                </div>
                            ) : (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    {/* Left arrow */}
                                    {currentRangeStart > 1 && (
                                        <button
                                            onClick={handlePrevRange}
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                                backgroundColor: '#f0f0f0',
                                                border: '1px solid #ddd',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            &lt;
                                        </button>
                                    )}

                                    {/* Page numbers */}
                                    {visiblePageNumbers.map((number) => (
                                        <button
                                            key={number}
                                            onClick={() => paginate(number)}
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                                backgroundColor: currentPage === number ? '#007bff' : '#f0f0f0',
                                                color: currentPage === number ? 'white' : 'black',
                                                border: currentPage === number ? '1px solid #007bff' : '1px solid #ddd',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {number}
                                        </button>
                                    ))}

                                    {/* Ellipsis if there are more pages */}
                                    {currentRangeStart + pagesToShow <= totalPages && (
                                        <span style={{ width: '30px', height: '40px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'default' }}>...</span>
                                    )}

                                    {/* Right arrow */}
                                    {currentRangeStart + pagesToShow <= totalPages && (
                                        <button
                                            onClick={handleNextRange}
                                            style={{
                                                width: '35px',
                                                height: '35px',
                                                backgroundColor: '#f0f0f0',
                                                border: '1px solid #ddd',
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            &gt;
                                        </button>
                                    )}

                                    {/* Items per page dropdown */}
                                    <div style={{ marginBottom: '10px' }}>
                                        <label htmlFor="itemsPerPage" style={{ marginLeft: '10px' }}>Items per page:</label>
                                        <select
                                            id="itemsPerPage"
                                            value={itemsPerPage}
                                            onChange={handleItemsPerPageChange}
                                            style={{ padding: '5px' }}
                                        >
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={150}>150</option>
                                            <option value={200}>200</option>
                                            <option value={500}>500</option>
                                            <option value={1000}>1000</option>
                                        </select>
                                    </div>
                                </div>
                            </div>)}
                        </Card.Body>
                    </Card>
                </Col>
                <DeleteConfirmation
                    showModal={deleteConfirmationModal}
                    proceedDelete="true"
                    confirmModal={deleteDocument}
                    hideModal={hideDeleteConfirmationModal}
                    uuid={uuid}
                    message={confirmMessage} />

                <DeleteConfirmation
                    showModal={deleteDocumentCategoryConfirmationModal}
                    proceedDelete="true"
                    confirmModal={deleteCategoryDocument}
                    hideModal={hideDeleteDocumentCategoryConfirmationModal}
                    documentUuid={documentUuid}
                    websiteRef={websiteRef}
                    categoryUuid={categoryUuid}
                    categoryDocumentId={categoryDocumentId}
                    message={confirmMessage} />

                <RetrainConfirmation
                    showModal={displayRetrainConfirmationModal}
                    proceedTrain={proceedTrain}
                    confirmModal={updateUrlDocument}
                    hideModal={hideRetrainConfirmationModal}
                    uuid={uuid}
                    url={selectedUrls}
                    message={confirmMessage} />

            </Row>
        </>
    )
}

export default SiteDocs
