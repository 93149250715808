import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import DeleteConfirmation from './DeleteConfirmation';
import moment from "moment";


import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios';
import '../../../assets/scss/component.scss'
import { error, success } from "../../../notify";


const SiteCategories = (props) => {
   let history = useNavigate();
   const ERR_NETWORK_MESSAGE = 'Server is not available.'
   const token = sessionStorage.getItem("revizeTrainingToken");
   const [domainKey, setDomainKey] = useState("");
   const [domainName, setDomainName] = useState("");
   const [siteId, setSiteId] = useState("");
   const [categoryData, setCategoryData] = useState([]);
   const categoryApi = `${process.env.REACT_APP_SERVER_URL}/api/category-site/?siteId=`;
   const addCategoryApi = `${process.env.REACT_APP_SERVER_URL}/api/category`;
   const deleteCategoryApi = `${process.env.REACT_APP_SERVER_URL}/api/category/`;
   const updateCategoryApi = `${process.env.REACT_APP_SERVER_URL}/api/category/`;
   const [uuid, setUuid] = useState(null);
   const [categoryId, setCategoryId] = useState("");
   const [categoryName, setCategoryName] = useState("");
   const [description, setDescription] = useState("");
   const defaultShowInBot = true;
   const [showInBot, setShowInBot] = useState(defaultShowInBot);
   const [categoryNameError, setCategoryNameError] = useState("");
   const [descriptionError, setDescriptionError] = useState("");
   const [confirmMessage, setConfirmMessage] = useState(null);
   const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);


   const [showAddCategory, setShowAddCategory] = useState(false);


   const [currentUpdateCategory, setCurrentUpdateCategory] = useState({
       categoryName: '',
       description: '',
       showInBot: defaultShowInBot
     });
  
   const handleCloseAddCategory = () => {
       setShowAddCategory(false);
       setCategoryName("");
       setDescription("");
       setCategoryNameError("");
       setDescriptionError("");
       setShowInBot(true);
   }
   const handleShowAddCategory = () => setShowAddCategory(true);
  
   const [showUpdateCategory, setShowUpdateCategory] = useState(false);
   const handleCloseUpdateCategory = () => {
       setShowUpdateCategory(false);
       setCategoryNameError("");
       setDescriptionError("");
       setShowInBot(true);
     };
   const handleShowUpdateCategory = (id, name, description, showinbot) => {
       setShowUpdateCategory(true);
       setCategoryId(id);
       setCategoryName(name);
       setDescription(description);
       setShowInBot(showinbot);
   }


   let loaded = false;
   const location = useLocation();


   useEffect(() => {
       if(!loaded && token) {
           // eslint-disable-next-line
           loaded = true;
           if(null === location.state) {
               history('/site')
               return;
           }
           setSiteId(location.state.siteId);
           setDomainName(location.state.siteName);
           setDomainKey(location.state.siteKey);
           fetchData(location.state.siteId);
       }
  
   }, [])


   async function fetchData(siteId) {
      
       if(siteId) {
           const headers = { "Content-Type": "application/json", "Authorization": "Bearer " +  token};
      
           axios.get(categoryApi + siteId, {headers: headers})
           .then((response) => {
               const data = response.data;
               setCategoryData(data);
           }).catch((err) => {
               if(err === 'ERR_NETWORK') {
                   error(ERR_NETWORK_MESSAGE, true)
               } else {
                   error(err.response.data.detail, true)  
               }
               history('/site');
             });
       }
   }


   const handleCategoryName = (event) => {
       setCategoryName(event.trim());
   };
  


   const handleShowInBot = () => {
       setShowInBot(!showInBot);
   };


   const handleDescription = (event) => {
       setDescription(event.trim());
   };


   const validateCategoryName = (event) => {
       event.preventDefault();
       const categoryNamePattern = /[a-zA-Z]/;
       if(categoryName !== "") {
           setCategoryNameError("")
           if (!categoryNamePattern.test(categoryName)) {
               setCategoryNameError("Category Name is invalid");   
           }
       } else {
           setCategoryNameError("Category Name is mandatory");
       }
   }


   const validateDescription = (event) => {
       event.preventDefault();
       const descriptionPattern = /[a-zA-Z]/;
       if(description !== "") {
           setDescriptionError("")
           if (!descriptionPattern.test(description)) {
               setDescriptionError("Description is invalid");   
           }
       } else {
           setDescriptionError("Description is mandatory");
       }
   }
  
   const addCategory = () => {
       if(token) {
           const headers = { "Content-Type": "application/json", "Authorization": "Bearer " +  token};
           var data = JSON.stringify({
               "name": categoryName,
               "website_id": siteId,
               "description": description,
               "show_in_bot": showInBot
           });
      
           axios.post(addCategoryApi, data, {headers: headers})
           .then((response) => {
               const data = response.data;
               setCategoryData(oldData => [...oldData, data]);
               success("Category added successfully.", true);
           })
           .catch((err) => {
               if(err === 'ERR_NETWORK') {
                   error(ERR_NETWORK_MESSAGE, true)
               } else {
                   error(err.response.data.detail, true)  
               }
           });
       }
       setCategoryName("");
       setDescription("");
       setShowInBot(true);
   };


   const updateCategory = () => {
       const hasChanges = (
           currentUpdateCategory.categoryName !== categoryName ||
           currentUpdateCategory.description !== description||
           currentUpdateCategory.showInBot !== showInBot
       );
  
       if (token && hasChanges) {
           const headers = { "Content-Type": "application/json", "Authorization": "Bearer " + token };
           const data = JSON.stringify({
               "category_request": {
                   "name": categoryName,
                   "website_id": siteId,
                   "description": description,
                   "show_in_bot": showInBot
               }
           });
  
           axios.put(updateCategoryApi + categoryId, data, { headers: headers })
               .then(() => {
                   success("Category updated successfully.", true);
                   updateCategoryData(categoryId);
               })
               .catch((err) => {
                   if (err.message === 'Network Error') {
                       error(ERR_NETWORK_MESSAGE, true);
                   } else {
                       error(err.response?.data?.detail || "An error occurred", true);
                   }
               });
  
           setCategoryName("");
           setDescription("");
       } else if (!hasChanges) {
           console.log("No changes detected.");
       }
   };
  


   const updateCategoryData = (categoryId) => {
       const currentCategoryIndex = categoryData.findIndex((category) => category.id === categoryId);
       const updatedCategory = Object.assign({}, categoryData[currentCategoryIndex]);
       updatedCategory.name = categoryName;
       updatedCategory.description = description;
       updatedCategory.website_id = siteId
       updatedCategory.show_in_bot = showInBot
       const newCategoryData = categoryData.slice();
       newCategoryData[currentCategoryIndex] = updatedCategory;
       setCategoryData(newCategoryData);
   };


   const deleteCategory = () => {
       // console.log("domainKey: " + domainKey);
       // console.log("siteId: " + siteId);
       const headers = { "accept": "application/json", "Authorization": "Bearer " +  token};
  
       axios.delete(deleteCategoryApi + uuid + "/?websiteRef=" + domainKey, {headers: headers})
       .then((response) => {
           const data = response.data;
           success(data, true);
           setCategoryData(
               categoryData.filter((val) => {
                   return val.uuid !== uuid;
               })
           );
       })
       .catch((err) => {
           if(err === 'ERR_NETWORK') {
               error(ERR_NETWORK_MESSAGE, true)
           } else {
               error(err.response.data.detail, true)  
           }
       });


       setDeleteConfirmationModal(false);
     };
  
   const [proceedDelete, setProceedDelete] = useState(false);
   const showDeleteModal = (uuid, categoryName, category_document_count) => {
       if (category_document_count === undefined | category_document_count === 0){
           setUuid(uuid);
           setConfirmMessage("Are you sure you want to delete the category: " + categoryName + "?");
           setDeleteConfirmationModal(true);
           setProceedDelete(true);
           loaded = true;
       } else {
           setConfirmMessage("The category " + categoryName + " cannot be deleted. There are trained documents for category. ");
           setDeleteConfirmationModal(true);
           setProceedDelete(false);
       }
   };


   // Hide the modal
   const hideDeleteConfirmationModal = () => {
       setDeleteConfirmationModal(false);
   };


   const handleModalShow = () => {
       setCurrentUpdateCategory({
           categoryName: categoryName,
           description: description,
       });
     };


   const areAllCategoryFieldsFilled = categoryName !== "" && description !== "" && categoryNameError === "" && descriptionError === "";


   return (
       <>
       <Row>
           <Col sm="12">
               <Card>
                   <Card.Header className="d-flex justify-content-between flex-wrap">
                       <div className="header-title">
                           <h4 className="card-title mb-0">Categories - {domainName}</h4>
                       </div>
                       <div>
                           <Button className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3" onClick={handleShowAddCategory}>
                               <i className="btn-inner">
                                   <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                   </svg>
                               </i>
                               <span>Add Category</span>
                           </Button>
                           <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3">
                               <Link
                                   aria-current="page"
                                   to="/site"
                                   >
                                   <span style={{'color': 'white'}}> Back </span>
                               </Link>
                           </Button>
                           <Modal show={showAddCategory} onHide={handleCloseAddCategory}>
                               <Modal.Header closeButton>
                                   <Modal.Title>Add Category</Modal.Title>
                               </Modal.Header>
                               <Modal.Body>
                                   <Form.Group className="mb-3" controlId="formBasicPassword">
                                       <Form.Label>Category Name</Form.Label>
                                       <Form.Control style={{borderColor: categoryNameError.length > 0 ? "red" : null}}
                                           type="text"
                                           placeholder="Category Name"
                                           onChange={e => handleCategoryName(e.target.value)}
                                           onBlur={validateCategoryName}
                                           maxLength={30} />
                                       <span style={{color:"red"}}>
                                           {categoryNameError}
                                       </span>
                                   </Form.Group>
                                   <Form.Group className="mb-3" controlId="formBasicPassword">
                                       <Form.Label>Description</Form.Label>
                                       <Form.Control style={{borderColor: descriptionError.length > 0 ? "red" : null}}
                                           type="textarea"
                                           placeholder="Description"
                                           onChange={e => handleDescription(e.target.value)}
                                           onBlur={validateDescription}
                                           maxLength={100} />
                                       <span style={{color:"red"}}>
                                           {descriptionError}
                                       </span>
                                   </Form.Group>
                                   <Form.Group className="mb-3" controlId="formBasicPassword">
                                       <Form.Check
                                           type="checkbox"
                                           label="Show in Chat-Bot"
                                           checked={showInBot}
                                           onChange={e => handleShowInBot(e.target.value)}
                                       />
                                   </Form.Group>
                               </Modal.Body>
                               <Modal.Footer>
                                   <Button variant="secondary" onClick={handleCloseAddCategory}>
                                       Cancel
                                   </Button>{' '}
                                   <Button disabled={!areAllCategoryFieldsFilled} variant="primary" onClick={
                                       () => {
                                           addCategory()
                                           handleCloseAddCategory()
                                       }
                                   }
                                   >
                                       Add
                                   </Button>
                               </Modal.Footer>
                           </Modal>
                          
                           <Modal show={showUpdateCategory} onHide={handleCloseUpdateCategory} onShow={handleModalShow}>
                               <Modal.Header closeButton>
                                   <Modal.Title>Update Category</Modal.Title>
                               </Modal.Header>
                               <Modal.Body>
                                   <Form.Group className="mb-3" controlId="formBasicPassword">
                                       <Form.Label>Category Name</Form.Label>
                                       <Form.Control style={{borderColor: categoryNameError.length > 0 ? "red" : null}}
                                           type="text"
                                           placeholder="Category Name"
                                           defaultValue={categoryName}
                                           onChange={e => handleCategoryName(e.target.value)}
                                           onBlur={validateCategoryName}
                                           maxLength={100} />
                                       <span style={{color:"red"}}>
                                           {categoryNameError}
                                       </span>
                                   </Form.Group>
                                   <Form.Group className="mb-3" controlId="formBasicPassword">
                                       <Form.Label>Description</Form.Label>
                                       <Form.Control style={{borderColor: descriptionError.length > 0 ? "red" : null}}
                                           type="textarea"
                                           placeholder="Description"
                                           defaultValue={description}
                                           onChange={e => handleDescription(e.target.value)}
                                           onBlur={validateDescription}
                                           maxLength={100} />
                                       <span style={{color:"red"}}>
                                           {descriptionError}
                                       </span>
                                   </Form.Group>
                                   <Form.Group className="mb-3" controlId="formBasicPassword">
                                       <Form.Check
                                           type="checkbox"
                                           label="Show in Chat-Bot"
                                           checked={showInBot}
                                           onChange={e => handleShowInBot()}
                                       />
                                   </Form.Group>
                               </Modal.Body>
                               <Modal.Footer>
                                   <Button variant="secondary" onClick={handleCloseUpdateCategory} >
                                       Cancel
                                   </Button>{' '}
                                   <Button disabled={!areAllCategoryFieldsFilled} variant="primary" onClick={
                                       () => {
                                           updateCategory()
                                           handleCloseUpdateCategory()
                                       }
                                   } >Update</Button>
                               </Modal.Footer>
                           </Modal>
                       </div>
                   </Card.Header>
                   <Card.Body>
                       <div className="table-responsive">
                           <table className="table-hover table-striped table">
                               <thead>
                                   <tr>
                                   <th className="text-center">Category Name</th>
                                   <th className="text-center">Description</th>
                                   <th className="text-center">Document Count</th>
                                   <th className="text-center">Action</th>
                                   <th className="text-center">Created Time</th>
                                   </tr>
                               </thead>
                               <tbody>
                                   {categoryData.map((item, index) => (
                                       <tr key={index} >
                                           <td>
                                               {item.name}
                                           </td>
                                           <td width={"40%"} >
                                               {item.description}
                                           </td>
                                           <td width={"7%"} style={{ textAlign: "center" }}>
                                               {item.category_document_count}
                                           </td>
                                           <td width={"7%"} style={{ textAlign: "center" }}>
                                               <div>
                                                   <Link className="btn btn-sm btn-icon text-primary flex-end" data-bs-toggle="tooltip" title="Update Category" to="#"
                                                       onClick={() => { handleShowUpdateCategory(item.id, item.name, item.description, item.show_in_bot)}}
                                                       >
                                                       <span className="btn-inner">
                                                           <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
                                                               <path d="M11.4925 2.78906H7.75349C4.67849 2.78906 2.75049 4.96606 2.75049 8.04806V16.3621C2.75049 19.4441 4.66949 21.6211 7.75349 21.6211H16.5775C19.6625 21.6211 21.5815 19.4441 21.5815 16.3621V12.3341" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                               <path fillRule="evenodd" clipRule="evenodd" d="M8.82812 10.921L16.3011 3.44799C17.2321 2.51799 18.7411 2.51799 19.6721 3.44799L20.8891 4.66499C21.8201 5.59599 21.8201 7.10599 20.8891 8.03599L13.3801 15.545C12.9731 15.952 12.4211 16.181 11.8451 16.181H8.09912L8.19312 12.401C8.20712 11.845 8.43412 11.315 8.82812 10.921Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                               <path d="M15.1655 4.60254L19.7315 9.16854" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                           </svg>
                                                       </span>
                                                   </Link>
                                                   <Link className="btn btn-sm btn-icon text-danger " data-bs-toggle="tooltip" title="Delete Category" to="#" onClick={() => { showDeleteModal(item.uuid, item.name, item.category_document_count); }} >
                                                       <span className="btn-inner">
                                                           <svg width="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor">
                                                               <path d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                               <path d="M20.708 6.23975H3.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                               <path d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                                           </svg>
                                                       </span>
                                                   </Link>
                                               </div>
                                           </td>
                                           <td width={"10%"} style={{ textAlign: "center" }}>
                                               {moment(item.created_time).format("MM/DD/YY, HH:mm")}
                                           </td>
                                       </tr>
                                   ))
                                   }
                               </tbody>
                           </table>
                       </div>
                   </Card.Body>
               </Card>
           </Col>
           <DeleteConfirmation
               showModal={deleteConfirmationModal}
               proceedDelete={proceedDelete}
               confirmModal={deleteCategory}
               hideModal={hideDeleteConfirmationModal}
               uuid={uuid}
               categoryName={categoryName}
               message={confirmMessage} />


       </Row>
       </>
   )
}


export default SiteCategories




