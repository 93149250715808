import React, { useState, useEffect, useRef } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row, Form } from "react-bootstrap";
import Card from "../../../components/Card";
import DeleteConfirmation from "./DeleteConfirmation";
import axios from "axios";
import { error, success } from "../../../notify";


const SiteUsers = (props) => {
 const formRef = useRef();
 let history = useNavigate();
 const ERR_NETWORK_MESSAGE = "Server is not available.";
 const token = sessionStorage.getItem("revizeTrainingToken");
 const loggedInUserId = sessionStorage.getItem("loggedInUserId");
 const userRole = sessionStorage.getItem("userRole");
 const [siteUserData, setSiteUserData] = useState([]);
 const [siteId, setSiteId] = useState("");
 const [domainName, setDomainName] = useState("");
 const [userId, setUserId] = useState("");
 const siteUserApi = `${process.env.REACT_APP_SERVER_URL}/api/website/users?websiteId=`;
 const addSiteUserApi = `${process.env.REACT_APP_SERVER_URL}/api/site/user`;
 const editSiteUserApi = `${process.env.REACT_APP_SERVER_URL}/api/user/`;
 const deleteSiteUserApi = `${process.env.REACT_APP_SERVER_URL}/api/user/`;
 const emailExistApi = `${process.env.REACT_APP_SERVER_URL}/api/user/email/?email=`;
 const resetPasswordApi = `${process.env.REACT_APP_SERVER_URL}/api/user/resetPassword/?user_id=`;
 const setPageLimitURL = `${process.env.REACT_APP_SERVER_URL}/api/set-page-limit/`;




 const [firstName, setFirstName] = useState("");
 const [firstNameError, setFirstNameError] = useState("");
 const [lastName, setLastName] = useState("");
 const [lastNameError, setLastNameError] = useState("");
 const [email, setEmail] = useState("");
 const [password, setPassword] = useState("");
 const [confirmPassword, setConfirmPassword] = useState("");
 const [passwordError, setPasswordError] = useState("");
 const [confirmPasswordError, setConfirmPasswordError] = useState("");
 const [isActive, setActive] = useState(true);
 const [role, setRole] = useState("TRAINER");
 const [emailError, setEmailError] = useState(false);


 const [deleteMessage, setDeleteMessage] = useState(null);


 const userRolesList = ["SITEADMIN", "TRAINER"];


 const [showAddUser, setShowAddUser] = useState(false);
 const handleCloseAddUser = () => {
   setFirstName("");
   setFirstNameError("");
   setLastName("");
   setLastNameError("");
   setEmail("");
   setEmailError("");
   setPassword("");
   setPasswordError("");
   setConfirmPassword("");
   setConfirmPasswordError("");
   setActive(true);
   setShowAddUser(false);
   setRole("TRAINER");
 };
 const handleShowAddUser = () => setShowAddUser(true);


 const [showEditUser, setShowEditUser] = useState(false);
 const handleCloseEditUser = () => {
   setFirstNameError("");
   setLastNameError("");
   setEmailError("");
   setPasswordError("");
   setConfirmPasswordError("");
   setShowEditUser(false);
 };


 const [showDeleteUser, setShowDeleteUser] = useState(false);
 const handleCloseDeleteUser = () => setShowDeleteUser(false);


 const [showChangePassword, setShowChangePassword] = useState(false);
 const handleCloseChangePassword = () => {
   setPassword("");
   setConfirmPassword("");
   setPasswordError("");
   setConfirmPasswordError("");
   setShowChangePassword(false);
 };


 const handleFirstName = (event) => {
   setFirstName(event.trim());
 };


 const handleLastName = (event) => {
   setLastName(event.trim());
 };


 const handleEmail = (event) => {
   setEmail(event.trim());
 };


 const handlePassword = (event) => {
   event.persist();
   setPassword(event.target.value);
   // setPassword(event.trim());
 };


 const handleConfirmPassword = (event) => {
   event.persist();
   setConfirmPassword(event.target.value);
   // setConfirmPassword(event.trim());
 };
 const showRole = () => {
   console.log("Role inside handleRole", role);
 };
 const handleRole = (event) => {
   console.log("event.trim()", event.trim());
   setRole(event.trim());
   showRole();
 };


 const handleActive = (event) => {
   setActive(event);
 };


 const validatePassword = (event) => {
   event.preventDefault();
   if (password === "") {
     setPasswordError("Password is mandatory");
   } else if (confirmPassword !== "" && password !== confirmPassword) {
     setPasswordError("");
     setConfirmPasswordError("Password and confirm password is not matching");
   } else {
     setPasswordError("");
     setConfirmPasswordError("");
   }
 };


 const validateConfirmPassword = (event) => {
   event.preventDefault();
   if (confirmPassword === "") {
     setConfirmPasswordError("Confirm Password is mandatory");
   } else if (password !== confirmPassword) {
     setConfirmPasswordError("Password and confirm password is not matching");
   } else {
     setConfirmPasswordError("");
   }
 };


 const validateFirstName = (event) => {
   event.preventDefault();
   const firstNamePattern = /[a-zA-Z]/;
   if (firstName !== "") {
     setFirstNameError("")
     if (firstName.length < 1) {
       setFirstNameError("First Name should have atleast 1 Character");
     } else if (firstName.length > 30) {
       setFirstNameError("First Name should not exceed 30 Characters");
     } else if (!firstNamePattern.test(firstName)) {
       setFirstNameError("First Name is invalid");
     }
   } else {
     setFirstNameError("First Name is mandatory");
   }
 }


 const validateLastName = (event) => {
   event.preventDefault();
   const lastNamePattern = /[a-zA-Z]/;
   if (lastName !== "") {
     setLastNameError("")
     if (lastName.length < 1) {
       setLastNameError("Last Name should have atleast 1 Character");
     } else if (lastName.length > 30) {
       setLastNameError("Last Name should not exceed 30 Characters");
     } else if (!lastNamePattern.test(lastName)) {
       setLastNameError("Last Name is invalid");
     }
   } else {
     setLastNameError("Last Name is mandatory");
   }
 }


 const validateEmail = (event) => {
   event.preventDefault();
   if (email !== "") {
     if (!isValidEmail(email)) {
       setEmailError("Email is invalid");
       return;
     } else {
       setEmailError("");
     }
   } else {
     setEmailError("Email is mandatory");
   }
 };


 const validateEmailExist = (event) => {
   event.preventDefault();
   if (email !== "") {
     if (!isValidEmail(email)) {
       setEmailError("Email is invalid");
       return;
     } else {
       setEmailError("");
     }
     const headers = {
       "Content-Type": "application/json",
       Authorization: "Bearer " + token,
     };


     axios
       .get(emailExistApi + email, { headers: headers })
       .then((response) => {
         const data = response.data;
         if (data) {
           setEmailError("Email is used for existing user. Try another email");
         } else {
           setEmailError("");
         }
       })
       .catch((err) => {
         if (err === "ERR_NETWORK") {
           error(ERR_NETWORK_MESSAGE, true);
         } else {
           error(err.response.data.detail, true);
         }
       });
   } else {
     setEmailError("Email is mandatory");
   }
 };


 function isValidEmail(email) {
   // return /\S+@\S+\.\S+/.test(email);
   const emailRegex = /^[\w.]+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
   console.log("validate email", emailRegex.test(email));
   return emailRegex.test(email);
 }


 let loaded = false;
 const location = useLocation();


 useEffect(() => {
   if (!loaded && token) {
     // eslint-disable-next-line
     loaded = true;
     if (null === location.state) {
       history("/site");
       return;
     }
     setSiteId(location.state.siteId);
     setDomainName(location.state.siteName);
     fetchData(location.state.siteId);
   }
 }, []);


 async function fetchData(siteId) {
   if (siteId) {
     const headers = {
       "Content-Type": "application/json",
       Authorization: "Bearer " + token,
     };


     axios
       .get(siteUserApi + siteId, { headers: headers })
       .then((response) => {
         const data = response.data;
         setSiteUserData(data);
       })
       .catch((err) => {
         if (err === "ERR_NETWORK") {
           error(ERR_NETWORK_MESSAGE, true);
         } else {
           error(err.response.data.detail, true);
         }
         history("/site");
       });
   }
 }


 const addUser = () => {
   if (token) {
     const headers = {
       "Content-Type": "application/json",
       Authorization: "Bearer " + token,
     };
     var data = JSON.stringify({
       newUser: {
         first_name: firstName,
         last_name: lastName,
         email: email,
         hashed_password: password,
         is_active: isActive,
         role: role,
       },
       websiteId: siteId,
     });


     axios
       .post(addSiteUserApi, data, { headers: headers })
       .then((response) => {
         const data = response.data;
         setSiteUserData((oldData) => [...oldData, data]);
         success("User added successfully.", true);


         try {
           axios.post(setPageLimitURL,
             {
               page_limit: 100
             },
             {
               headers: headers,
               params: { user_id: data.id }
             }
           );


         } catch (setLimitErr) {
           console.error('Error setting page limit:', setLimitErr);
           error('Error setting page limit', true);
         }


       })
       .catch((err) => {
         if (err === "ERR_NETWORK") {
           error(ERR_NETWORK_MESSAGE, true);
         } else {
           error(err.response.data.detail, true);
         }
       });


     setFirstName("");
     setLastName("");
     setEmail("");
     setPassword("");
     setActive(true);
   }
 };
 const [currentEditUser, setCurrentEditUser] = useState({
   firstName: "",
   lastName: "",
   email: "",
   is_active: true,
   role: "",
 });
 const handleModalShow = () => {
   console.log("modal opened");
 };


 const editUser = () => {
   if (token) {
     console.log("currentEditUser.role", currentEditUser.role);
     console.log("role", role);
     const hasChanges =
       currentEditUser.firstName !== firstName ||
       currentEditUser.lastName !== lastName ||
       currentEditUser.email !== email ||
       currentEditUser.is_active !== isActive ||
       currentEditUser.role !== role;


     // console.log("Comparison of currentEditUser with new values:");
     // console.log("First Name:", {
     //   current: currentEditUser.firstName,
     //   new: firstName,
     //   changed: currentEditUser.firstName !== firstName,
     // });
     // console.log("Last Name:", {
     //   current: currentEditUser.lastName,
     //   new: lastName,
     //   changed: currentEditUser.lastName !== lastName,
     // });
     // console.log("Email:", {
     //   current: currentEditUser.email,
     //   new: email,
     //   changed: currentEditUser.email !== email,
     // });
     // console.log("Is Active:", {
     //   current: currentEditUser.is_active,
     //   new: isActive,
     //   changed: currentEditUser.is_active !== isActive,
     // });
     // console.log("Role:", {
     //   current: currentEditUser.role,
     //   new: role,
     //   changed: currentEditUser.role !== role,
     // });




     if (hasChanges) {
       const headers = {
         "Content-Type": "application/json",
         Authorization: "Bearer " + token,
       };
       var data = JSON.stringify({
         user_request: {
           first_name: firstName,
           last_name: lastName,
           email: email,
           is_active: isActive,
           role: role,
         },
         website_id: siteId,
       });


       console.log("data", data);


       axios
         .put(editSiteUserApi + userId, data, { headers: headers })
         .then(() => {
           updateUserData(userId);
           success("User updated successfully.", true);
         })
         .catch((err) => {
           if (err === "ERR_NETWORK") {
             error(ERR_NETWORK_MESSAGE, true);
           } else {
             error(err.response.data.detail, true);
           }
         });


       setFirstName("");
       setLastName("");
       setEmail("");
       setActive(isActive);
       setRole(role);
     }
   }
 };


 const handleShowEditUser = (
   userId,
   firstName,
   lastName,
   email,
   role,
   isActive
 ) => {
   setCurrentEditUser({
     firstName: firstName,
     lastName: lastName,
     email: email,
     is_active: isActive,
     role: role,
   });


   setUserId(userId);
   setFirstName(firstName);
   setFirstNameError("");
   setLastName(lastName);
   setLastNameError("");
   setEmail(email);
   setEmailError("");
   setShowEditUser(true);
 };


 const handleShowDeleteUser = (userId, firstName, lastName) => {
   setUserId(userId);
   setFirstName(firstName);
   setLastName(lastName);
   setDeleteMessage(
     "Are you sure you want to delete the user: " +
     (firstName.length > 20
       ? firstName.substring(0, 20) + "..."
       : firstName) +
     " " +
     (lastName.length > 20 ? lastName.substring(0, 20) + "..." : lastName) +
     "?"
   );
   setShowDeleteUser(true);
 };


 const handleShowChangePassword = (userId, password, confirmPassword) => {
   setUserId(userId);
   setShowChangePassword(true);
 };


 const updateUserData = (userId) => {
   const currentUserIndex = siteUserData.findIndex(
     (user) => user.id === userId
   );
   const updatedUser = Object.assign({}, siteUserData[currentUserIndex]);
   updatedUser.first_name = firstName;
   updatedUser.last_name = lastName;
   updatedUser.email = email;
   updatedUser.hashed_password = password;
   updatedUser.is_active = isActive;
   updatedUser.role = role;
   const newSiteUserData = siteUserData.slice();
   newSiteUserData[currentUserIndex] = updatedUser;
   setSiteUserData(newSiteUserData);
 };


 const deleteUser = () => {
   const headers = {
     "Content-Type": "application/json",
     Authorization: "Bearer " + token,
   };
   console.log("SiteId: " + siteId);
   axios
     .delete(
       deleteSiteUserApi + userId,
       { data: { website_id: siteId } },
       { headers: headers }
     )
     .then((response) => {
       const data = response.data;
       success(data, true);
       setSiteUserData(
         siteUserData.filter((val) => {
           return val.id !== userId;
         })
       );
     })
     .catch((err) => {
       if (err === "ERR_NETWORK") {
         error(ERR_NETWORK_MESSAGE, true);
       } else {
         error(err.response.data.detail, true);
       }
     });


   setShowDeleteUser(false);
 };


 const changePassword = () => {
   const headers = {
     "Content-Type": "application/json",
     Authorization: "Bearer " + token,
   };
   var data = JSON.stringify({ newPassword: password, website_id: siteId });


   axios
     .put(resetPasswordApi + userId, data, { headers: headers })
     .then((response) => {
       const data = response.data;
       success(data, true);
     })
     .catch((err) => {
       if (err === "ERR_NETWORK") {
         error(ERR_NETWORK_MESSAGE, true);
       } else {
         error(err.response.data.detail, true);
       }
     });


   setPassword("");
   setConfirmPassword("");
 };


 const areAllAddUserFieldsFilled =
   firstName !== "" &&
   lastName !== "" &&
   email !== "" &&
   password !== "" &&
   confirmPassword !== "" &&
   firstNameError === "" &&
   lastNameError === "" &&
   emailError === "" &&
   passwordError === "" &&
   confirmPasswordError === "";
 const areAllEditUserFieldsFilled =
   firstName !== "" &&
   lastName !== "" &&
   email !== "" &&
   firstNameError === "" &&
   lastNameError === "" &&
   emailError === "";
 const areAllChangePasswordFieldsFilled =
   password !== "" &&
   confirmPassword !== "" &&
   passwordError === "" &&
   confirmPasswordError === "";


 return (
   <Row>
     <Col sm="12">
       <Card>
         <Card.Header className="d-flex justify-content-between flex-wrap">
           <div className="header-title">
             <h4 className="card-title mb-0">Users - {domainName}</h4>
           </div>
           <div>
             <Button
               className="text-center btn-primary btn-icon me-2 mt-lg-0 mt-md-0 mt-3"
               onClick={handleShowAddUser}
             >
               <i className="btn-inner">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   className="h-6 w-6"
                   fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth="2"
                     d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                   />
                 </svg>
               </i>
               <span>Add User</span>
             </Button>
             <Button className="text-center btn-primary btn-icon ms-2 mt-lg-0 mt-md-0 mt-3">
               <Link aria-current="page" to="/site">
                 <span style={{ color: "white" }}> Back </span>
               </Link>
             </Button>
             <Modal show={showAddUser} onHide={handleCloseAddUser}>
               <Modal.Header closeButton>
                 <Modal.Title>Add User</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>First Name</Form.Label>
                   <Form.Control
                     style={{
                       borderColor: firstNameError.length > 0 ? "red" : null,
                     }}
                     type="text"
                     placeholder="First Name"
                     onChange={(e) => handleFirstName(e.target.value)}
                     onBlur={validateFirstName}
                     maxLength={30}
                   />
                   <span style={{ color: "red" }}>{firstNameError}</span>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Last Name</Form.Label>
                   <Form.Control
                     style={{
                       borderColor: lastNameError.length > 0 ? "red" : null,
                     }}
                     type="text"
                     placeholder="Last Name"
                     onChange={(e) => handleLastName(e.target.value)}
                     onBlur={validateLastName}
                     maxLength={30}
                   />
                   <span style={{ color: "red" }}>{lastNameError}</span>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Email</Form.Label>
                   <Form.Control
                     style={{
                       borderColor: emailError.length > 0 ? "red" : null,
                     }}
                     type="email"
                     placeholder="Email"
                     onChange={(e) => handleEmail(e.target.value)}
                     onBlur={validateEmailExist}
                     maxLength={100}
                   />
                   <span style={{ color: "red" }}>{emailError}</span>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Password</Form.Label>
                   <Form.Control
                     style={{
                       borderColor: passwordError.length > 0 ? "red" : null,
                     }}
                     type="password"
                     placeholder="Password"
                     onChange={handlePassword}
                     onBlur={validatePassword}
                     maxLength={200}
                   />
                   <span style={{ color: "red" }}>{passwordError}</span>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Confirm Password</Form.Label>
                   <Form.Control
                     style={{
                       borderColor:
                         confirmPasswordError.length > 0 ? "red" : null,
                     }}
                     type="password"
                     placeholder="Confirm Password"
                     onChange={handleConfirmPassword}
                     onBlur={validateConfirmPassword}
                     maxLength={200}
                   />
                   <span style={{ color: "red" }}>{confirmPasswordError}</span>
                 </Form.Group>
                 <Form.Group>
                   <Form.Label>Active</Form.Label>
                   <Form.Check>
                     <Form.Check.Input
                       type="radio"
                       name="active"
                       id="active"
                       onChange={(e) => handleActive(true)}
                       defaultChecked
                     />{" "}
                     <Form.Check.Label htmlFor="active" className="pl-2">
                       True
                     </Form.Check.Label>
                   </Form.Check>
                   <Form.Check className="mb-3">
                     <Form.Check.Input
                       type="radio"
                       name="active"
                       id="active"
                       onChange={(e) => handleActive(false)}
                     />{" "}
                     <Form.Check.Label htmlFor="active" className="pl-2">
                       False
                     </Form.Check.Label>
                   </Form.Check>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Role</Form.Label>
                   {userRole === "ADMIN" && (
                     <Form.Select
                       className=" form-select-sm mb-3"
                       aria-label=".form-select-sm example"
                       onChange={(e) => handleRole(e.target.value)}
                     >
                       <option defaultValue="TRAINER">TRAINER</option>
                       <option defaultValue="SITEADMIN">SITEADMIN</option>
                     </Form.Select>
                   )}
                   {userRole === "SITEADMIN" && (
                     <Form.Select
                       className=" form-select-sm mb-3"
                       aria-label=".form-select-sm example"
                       onChange={(e) => handleRole(e.target.value)}
                     >
                       <option defaultValue="TRAINER">TRAINER</option>
                     </Form.Select>
                   )}
                 </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                 <Button variant="secondary" onClick={handleCloseAddUser}>
                   Cancel
                 </Button>{" "}
                 <Button
                   disabled={!areAllAddUserFieldsFilled}
                   variant="primary"
                   onClick={() => {
                     addUser();
                     handleCloseAddUser();
                   }}
                 >
                   Add
                 </Button>
               </Modal.Footer>
             </Modal>


             <Modal
               show={showEditUser}
               onHide={handleCloseEditUser}
               onShow={handleModalShow}
             >
               <Modal.Header closeButton>
                 <Modal.Title>Edit User</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>First Name</Form.Label>
                   <Form.Control
                     type="text"
                     placeholder="First Name"
                     defaultValue={firstName}
                     onChange={(e) => handleFirstName(e.target.value)}
                     onBlur={validateFirstName}
                     maxLength={30}
                   />
                   <span style={{ color: "red" }}>{firstNameError}</span>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Last Name</Form.Label>
                   <Form.Control
                     type="text"
                     placeholder="Last Name"
                     defaultValue={lastName}
                     onChange={(e) => handleLastName(e.target.value)}
                     onBlur={validateLastName}
                     maxLength={30}
                   />
                   <span style={{ color: "red" }}>{lastNameError}</span>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Email</Form.Label>
                   <Form.Control
                     type="email"
                     placeholder="Email"
                     defaultValue={email}
                     onChange={(e) => handleEmail(e.target.value)}
                     onBlur={validateEmail}
                     maxLength={100}
                   />
                   <span style={{ color: "red" }}>{emailError}</span>
                 </Form.Group>
                 <Form.Group>
                   <Form.Label>Active</Form.Label>
                   <Form.Check>
                     <Form.Check.Input
                       type="radio"
                       name="active"
                       id="active"
                       onChange={(e) => handleActive(true)}
                       defaultChecked
                     />{" "}
                     <Form.Check.Label htmlFor="active" className="pl-2">
                       True
                     </Form.Check.Label>
                   </Form.Check>
                   <Form.Check className="mb-3">
                     <Form.Check.Input
                       type="radio"
                       name="active"
                       id="active"
                       onChange={(e) => handleActive(false)}
                     />{" "}
                     <Form.Check.Label htmlFor="active" className="pl-2">
                       False
                     </Form.Check.Label>
                   </Form.Check>
                 </Form.Group>
                 <Form.Group className="mb-3" controlId="formBasicPassword">
                   <Form.Label>Role</Form.Label>
                   {userRole === "ADMIN" && (
                     <Form.Select
                       className=" form-select-sm mb-3"
                       aria-label=".form-select-sm example"
                       onChange={(e) => handleRole(e.target.value)}
                     >
                       {userRolesList.map((role) => (
                         <option
                           key={role}
                           value={role.toUpperCase()}
                           selected={role == currentEditUser.role}
                         >
                           {role.toUpperCase()}
                         </option>
                       ))}
                     </Form.Select>
                   )}
                   {userRole === "SITEADMIN" && (
                     <Form.Select
                       className=" form-select-sm mb-3"
                       aria-label=".form-select-sm example"
                       onChange={(e) => handleRole(e.target.value)}
                     >
                       <option defaultValue="TRAINER">TRAINER</option>
                     </Form.Select>
                   )}
                 </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                 <Button variant="secondary" onClick={handleCloseEditUser}>
                   Cancel
                 </Button>{" "}
                 <Button
                   disabled={!areAllEditUserFieldsFilled}
                   variant="primary"
                   onClick={() => {
                     editUser();
                     handleCloseEditUser();
                   }}
                 >
                   Update
                 </Button>
               </Modal.Footer>
             </Modal>


             <Modal
               show={showChangePassword}
               onHide={handleCloseChangePassword}
             >
               <Modal.Header closeButton>
                 <Modal.Title>Reset Password</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                 <Form.Group className="form-group">
                   <Form.Label>Password</Form.Label>
                   <Form.Control
                     style={{
                       borderColor: passwordError.length > 0 ? "red" : null,
                     }}
                     type="password"
                     defaultValue=""
                     id="password"
                     placeholder="Password"
                     onChange={handlePassword}
                     onBlur={validatePassword}
                     maxLength={200}
                   />
                   <span style={{ color: "red" }}>{passwordError}</span>
                 </Form.Group>
                 <Form.Group className="form-group">
                   <Form.Label>Confirm Password</Form.Label>
                   <Form.Control
                     style={{
                       borderColor:
                         confirmPasswordError.length > 0 ? "red" : null,
                     }}
                     type="password"
                     defaultValue=""
                     id="confirm-password"
                     placeholder="Confirm Password"
                     onChange={handleConfirmPassword}
                     onBlur={validateConfirmPassword}
                     maxLength={200}
                   />
                   <span style={{ color: "red" }}>{confirmPasswordError}</span>
                 </Form.Group>
               </Modal.Body>
               <Modal.Footer>
                 <Button
                   variant="secondary"
                   onClick={handleCloseChangePassword}
                 >
                   Cancel
                 </Button>{" "}
                 <Button
                   disabled={!areAllChangePasswordFieldsFilled}
                   variant="primary"
                   onClick={() => {
                     changePassword();
                     handleCloseChangePassword();
                   }}
                 >
                   Update
                 </Button>
               </Modal.Footer>
             </Modal>
           </div>
         </Card.Header>
         <Card.Body>
           <div className="table-responsive">
             <table className="table-hover table-striped table">
               <thead>
                 <tr>
                   <th className="text-center">First Name</th>
                   <th className="text-center">Last Name</th>
                   <th className="text-center">Email</th>
                   <th className="text-center">Active</th>
                   <th className="text-center">Role</th>
                   <th className="text-center">Action</th>
                 </tr>
               </thead>
               <tbody>
                 {siteUserData.map((item, index) => (
                   <tr className="" key={index}>
                     <td>{item.first_name}</td>
                     <td>{item.last_name}</td>
                     <td>{item.email}</td>
                     <td width="7%">
                       {item.is_active ? "ACTIVE" : "INACTIVE"}
                     </td>
                     <td width="7%">{item.role}</td>
                     <td width="10%">
                       <div style={{ textAlign: "center" }}>
                         <Link
                           className="btn-sm btn-icon text-primary flex-end"
                           data-bs-toggle="tooltip"
                           title="Edit User"
                           to="#"
                           onClick={() => {
                             handleShowEditUser(
                               item.id,
                               item.first_name,
                               item.last_name,
                               item.email,
                               item.role,
                               item.is_active
                             );
                           }}
                         >
                           <span className="btn-inner">
                             <svg
                               width="32"
                               viewBox="0 0 24 24"
                               fill="none"
                               xmlns="http://www.w3.org/2000/svg"
                             >
                               <path
                                 d="M13.7476 20.4428H21.0002"
                                 stroke="currentColor"
                                 strokeWidth="1.5"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                               ></path>
                               <path
                                 fillRule="evenodd"
                                 clipRule="evenodd"
                                 d="M12.78 3.79479C13.5557 2.86779 14.95 2.73186 15.8962 3.49173C15.9485 3.53296 17.6295 4.83879 17.6295 4.83879C18.669 5.46719 18.992 6.80311 18.3494 7.82259C18.3153 7.87718 8.81195 19.7645 8.81195 19.7645C8.49578 20.1589 8.01583 20.3918 7.50291 20.3973L3.86353 20.443L3.04353 16.9723C2.92866 16.4843 3.04353 15.9718 3.3597 15.5773L12.78 3.79479Z"
                                 stroke="currentColor"
                                 strokeWidth="1.5"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                               ></path>
                               <path
                                 d="M11.021 6.00098L16.4732 10.1881"
                                 stroke="currentColor"
                                 strokeWidth="1.5"
                                 strokeLinecap="round"
                                 strokeLinejoin="round"
                               ></path>
                             </svg>
                           </span>
                         </Link>
                         <Link
                           className="btn-sm btn-icon text-primary"
                           data-bs-toggle="tooltip"
                           title="Change Password"
                           to="#"
                           onClick={() => {
                             handleShowChangePassword(item.id);
                           }}
                         >
                           <span className="btn-inner">
                             <svg
                               xmlns="http://www.w3.org/2000/svg"
                               viewBox="0 0 64 64"
                               fill="#3a57e8"
                             >
                               <path d="M57.71,7,56.32,5.59l-.06-.05-.14-.11L56,5.3l-.18-.1-.15-.07-.23-.07-.13,0L55,5h-.24l-6.53.43A2,2,0,0,0,46.38,7.3l-.31,4.77-4.77.32a2,2,0,0,0-1.86,1.86L39.12,19l-4.78.31a2,2,0,0,0-1.86,1.86L32.15,26l-4.78.31a2,2,0,0,0-1,.34A16.45,16.45,0,0,0,10.51,54.18l.34.32.1.09a16.38,16.38,0,0,0,22.83-.41,16.56,16.56,0,0,0,3.5-18.11l20.3-20.3a2,2,0,0,0,.59-1.37l.13-6A2,2,0,0,0,57.71,7ZM17.88,51.35a3.21,3.21,0,1,1,0-4.54,3.21,3.21,0,0,1,0,4.54Zm13.07,0a12.39,12.39,0,0,1-11,3.46A7.2,7.2,0,1,0,10.52,44a7.29,7.29,0,0,0-.62.71A12.45,12.45,0,0,1,26.58,30.91a11,11,0,0,1,1.14.49A12.48,12.48,0,0,1,31,33.74a12.8,12.8,0,0,1,2.16,2.87A12.52,12.52,0,0,1,31,51.36ZM54.19,13.51l-19,19a16.76,16.76,0,0,0-1.4-1.6c-.33-.33-.68-.65-1-.95l1.41-.09A2,2,0,0,0,36,28l.32-4.79,4.78-.31A2,2,0,0,0,43,21l.31-4.77,4.77-.32a2,2,0,0,0,1.86-1.86l.31-4.77,3.88-.25.14.14Z" />
                             </svg>
                           </span>
                         </Link>
                         {String(item.id) !== loggedInUserId ? (
                           <Link
                             className="btn-sm btn-icon text-danger "
                             data-bs-toggle="tooltip"
                             title="Delete User"
                             to="#"
                             onClick={() => {
                               handleShowDeleteUser(
                                 item.id,
                                 item.first_name,
                                 item.last_name
                               );
                             }}
                           >
                             <span className="btn-inner">
                               <svg
                                 width="20"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 stroke="currentColor"
                               >
                                 <path
                                   d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                   stroke="currentColor"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 ></path>
                                 <path
                                   d="M20.708 6.23975H3.75"
                                   stroke="currentColor"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 ></path>
                                 <path
                                   d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                   stroke="currentColor"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 ></path>
                               </svg>
                             </span>
                           </Link>
                         ) : (
                           <span
                             className="btn-sm btn-icon text-danger "
                             data-bs-toggle="tooltip"
                             title="You can not delete your own account."
                             onClick={() => {
                               alert("You can not delete your own account.");
                             }}
                           >
                             <span className="btn-inner">
                               <svg
                                 width="20"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                                 stroke="currentColor"
                               >
                                 <path
                                   d="M19.3248 9.46826C19.3248 9.46826 18.7818 16.2033 18.4668 19.0403C18.3168 20.3953 17.4798 21.1893 16.1088 21.2143C13.4998 21.2613 10.8878 21.2643 8.27979 21.2093C6.96079 21.1823 6.13779 20.3783 5.99079 19.0473C5.67379 16.1853 5.13379 9.46826 5.13379 9.46826"
                                   stroke="currentColor"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 ></path>
                                 <path
                                   d="M20.708 6.23975H3.75"
                                   stroke="currentColor"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 ></path>
                                 <path
                                   d="M17.4406 6.23973C16.6556 6.23973 15.9796 5.68473 15.8256 4.91573L15.5826 3.69973C15.4326 3.13873 14.9246 2.75073 14.3456 2.75073H10.1126C9.53358 2.75073 9.02558 3.13873 8.87558 3.69973L8.63258 4.91573C8.47858 5.68473 7.80258 6.23973 7.01758 6.23973"
                                   stroke="currentColor"
                                   strokeWidth="1.5"
                                   strokeLinecap="round"
                                   strokeLinejoin="round"
                                 ></path>
                               </svg>
                             </span>
                           </span>
                         )}
                       </div>
                     </td>
                   </tr>
                 ))}
               </tbody>
             </table>
           </div>
         </Card.Body>
       </Card>
     </Col>
     <DeleteConfirmation
       showModal={showDeleteUser}
       proceedDelete="true"
       confirmModal={deleteUser}
       hideModal={handleCloseDeleteUser}
       id={userId}
       message={deleteMessage}
     />
   </Row>
 );
};


export default SiteUsers;